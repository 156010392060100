.customDateInput {
  background-image: url('/public/images/inputDateIcon.svg');
  background-size: 16px;
  background-position: calc(100% - 23px) center;
  background-repeat: no-repeat;
  padding-right: 20px;
}

.customDateInput::-webkit-calendar-picker-indicator {
  opacity: 0.001;
  cursor: pointer;
}
