.Page404 {
  &-container {
    @include flexible(center, center, column, nowrap);
    gap: 1rem;
  }

  &-sadSmile {
    font-size: 20vmin;
    transition: transform 1s;
    will-change: transform;
  }

  &-sadSmile:hover {
    transform: rotate(360deg);
    cursor: none;
  }

  &-text--small {
    font-size: 3vmin;
    font-style: italic;
  }
}
