.pulse {
  width: 2px;
  height: 40px;
  background: #0a42d3;
  animation: pulse 1s infinite alternate ease;
  /* transition: all 1s; */
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
