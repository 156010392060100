body {
  background-color: #fff;
}

body * {
  font-family: 'TTNorms', sans-serif;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.ant-btn-primary {
  background-color: #1677ff !important;
}

.ant-btn-primary:disabled {
  background-color: lightgray !important;
}

.ant-select-selection-item {
  height: inherit;
}

h1 {
  margin: 1rem 0 0 0;
}

.ant-cascader,
.select {
  width: 100% !important;
}

button:focus,
button:hover {
  transition: all 0.25s;
  box-shadow: 4px 4px 4px grey;
  transform: translate3d(1px, 0, 16px);
}

button.no-hover:focus,
button.no-hover:hover {
  transition: none;
  box-shadow: none;
  transform: none;
}

button:disabled:hover {
  box-shadow: none;
  transform: none;
}
