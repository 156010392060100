.menu-container {
  @include flexible(center, center, row, nowrap);
  position: relative;
  border-bottom: 1px solid $secondaryColor;
  background-color: #fff;
  user-select: none;
  font-weight: 700;
}

@media screen and (min-width: 1280px) {
  .menu-container ul {
    display: flex;
    justify-content: center;
  }
}

.menu-BurgerButton {
  position: fixed;
  top: 4px;
  left: 4px;
  padding: 0 8px;
}
