@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

@font-face {
  font-family: 'TTNorms';
  font-style: normal;
  font-weight: 400;
  src: local('TTNorms Regular'), local('TTNorms-Regular'),
    url('../../../public/fonts/TTNorms-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'TTNorms';
  font-style: normal;
  font-weight: 500;
  src: local('TTNorms Medium'), local('TTNorms-Medium'),
    url('../../../public/fonts/TTNorms-Medium.woff2') format('woff2');
}
